
import GwIcon from '~/patterns/atoms/icon/icon.vue';
import GwButton from '~/patterns/atoms/button/button';
import GwCategory from '~/patterns/atoms/category/category';
import GwAvatar from '~/patterns/atoms/avatar/avatar';
import getCoursePageSlug from '~/graphql/queries/getCoursePageSlug.graphql';

export default {
    components: {
        GwIcon,
        GwButton,
        GwCategory,
        GwAvatar
    },
    props: {
        edition: {
            type: Object,
            default: () => { return {}; }
        },
    },
    data() {
        return {
            currency: this.$config.site.currencies[0],
            informationExpanded: false
        };
    },
    computed: {
        date() {
            return this.$d(new Date(this.edition?.start), 'dateShorter') || null;
        },
        price() {
            return this.addCurrency(this.edition?.prices);
        },
        isCoursePage() {
            return this.$route.name.includes('courses-category-slug');
        }
    },
    mounted() {
        // Set currency from local storage only on client
        if (localStorage.getItem('selectedCurrency')) {
            this.currency = localStorage.getItem('selectedCurrency');
        }

        // Watch for changes
        this.$nuxt.$on('selectedCurrency', () => {
            this.currency = localStorage.getItem('selectedCurrency');
        });
    },
    methods: {
        async openCourse() {
            const { data } = await this.$gql.executeQuery(getCoursePageSlug, {
                site: this.$config.appMultisiteHandle,
                course: this.edition?.course?.[0]?.id
            });

            const programSlug = this.edition?.programs?.[0]?.slug;

            await this.$router.push(`${this.localePath('/courses')}/${programSlug}/${data.entry.slug}`);
        },
        getPrice(prices) {
            return prices.filter(price => price.currency === this.currency)[0];
        },
        addCurrency(prices) {
            const price = this.getPrice(prices);

            const locale = this.$config.site.locale;
            const vatIncluded = false;
            const showVatText = locale === 'fr' && !vatIncluded;
            const exVatText = this.$t('schedule.ex-vat');

            function vat() {
                if (showVatText) {
                    return ` ${exVatText}`;
                } else {
                    return '';
                }
            }

            if (price && price.price !== undefined) {
                return `${this.$n(price.price, {
                    style: 'currency',
                    currency: price.currency,
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                })}${vat()}`;
            }
        },
        getBrochureLink(edition) {
            const path = '/form/brochure';
            const courseId = edition?.course[0]?.id;
            const editionCode = edition?.editionCode;
            // These MUST match the field "name" attributes in the form. Which in turn MUST match exactly the contact property in Hubspot.
            const query = `?course_id=${courseId}&edition=${editionCode}`;

            return path + query;
        },
        getCheckoutElement() {
            return this.$config.site.locale === 'fr' ? 'a' : 'router-link';
        },
        getCheckoutLink(editionId) {
            if (this.$config.site.locale === 'fr') {
                return 'https://gladwellacademy.typeform.com/to/HVmb3uzq';
            }

            return `/checkout?id=${editionId}`;
        }
    }
};
