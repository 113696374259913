
import Multiselect from '~/patterns/atoms/multiselect/multiselect.vue';
import GwIcon from '~/patterns/atoms/icon/icon.vue';

export default {
    components: {
        Multiselect,
        GwIcon
    },
    data() {
        return {
            options: [],
        };
    }
};
