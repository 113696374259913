
import GwSection from '~/patterns/organisms/section/section.vue';
import GwEditionsSchedule from '~/patterns/organisms/editions-schedule/editions-schedule';

export default {
    components: {
        GwEditionsSchedule,
        GwSection,
    },

    props: {
        heading: {
            type: String,
            default: null
        },
        courses: {
            type: Array,
            default: () => {
                return [];
            }
        },
        trainers: {
            type: Array,
            default: () => {
                return [];
            }
        },
        width: {
            type: String,
            default: 'page',
        },
        background: {
            type: String,
            default: 'accent',
        }
    }
};
