
import GwIcon from '~/patterns/atoms/icon/icon.vue';
export default {
    components: {
        GwIcon
    },
    props: {
        value: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            date: null,
            locale: {
                formatLocale: {
                    firstDayOfWeek: 1,
                }
            },
            open: false
        };
    },
    watch: {
        date() {
            this.$emit('dateChanged', this.date);
        }
    },
    methods: {
        disabledBeforeToday(date) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            return date < today;
        },
        toggleState() {
            this.open = !this.open;
        },
        handleClose() {
            this.open = false;
        }
    }
};
