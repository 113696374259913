
import GwIcon from '~/patterns/atoms/icon/icon';

export default {
    components: {
        GwIcon,
    },
    props: {
        currentCount: {
            type: Number,
            default: 0,
        },
        totalCount: {
            type: Number,
            default: 0,
        },
        pageSize: {
            type: Number,
            default: 0,
        }
    },
    computed: {
        hasNextPage() {
            return this.currentCount < this.totalCount;
        }
    },
    methods: {
        getNextPage() {
            this.$emit('getNextPage');
        }
    }
};
