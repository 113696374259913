
import GwButton from '~/patterns/atoms/button/button.vue';
import GwCategory from '~/patterns/atoms/category/category.vue';
export default {
    components: {
        GwButton,
        GwCategory
    },
    props: {
        programs: {
            type: Array,
            default: () => { return []; }
        },
        selectedProgram: {
            type: Object,
            default: () => { return {}; }
        }
    },
    methods: {
        filterProgram(program) {
            if (this.checkActive(program.id)) {
                this.$emit('filterChanged', {});
            } else {
                this.$emit('filterChanged', program);
            }
        },
        checkActive(id) {
            const selected = this.selectedProgram || { id: null };
            return id === selected.id;
        }
    }
};
