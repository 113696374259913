
import Bugsnag from '@bugsnag/js';
import Multiselect from '~/patterns/atoms/multiselect/multiselect.vue';
import GwIcon from '~/patterns/atoms/icon/icon.vue';

export default {
    components: {
        Multiselect,
        GwIcon
    },
    props: {
        validator: {
            type: Object,
            default: undefined
        },
        country: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            options: [],
            selectedCountry: null
        };
    },
    async fetch() {
        await this.getCountries();
    },
    computed: {
        flagEmoji() {
            if (this.options && this.options.length) {
                const flags = {};
                this.options.forEach(({ code }) => {
                    flags[code] = this.getFlagEmoji(code);
                });
                return flags;
            }
            return {};
        }
    },
    watch: {
        selectedCountry(payload) {
            this.$emit('countryChanged', payload);
        },
        country(country) {
            if (!country && this.selectedCountry !== null) {
                this.selectedCountry = null;
            }
        }
    },
    methods: {
        getFlagEmoji(countryCode) {
            const codePoints = countryCode
                .toUpperCase()
                .split('')
                .map(char => 127397 + char.charCodeAt());
            return String.fromCodePoint(...codePoints);
        },
        async getCountries() {
            const language = this.$config.site.locale;
            try {
                await this.$axios.get(this.$config.apiUrl + `/api/countries?locale=${language}`)
                    .then((response) => {
                        this.options = response.data;
                    });
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error);

                Bugsnag.notify(error);
            }
        }
    }
};
